import React from "react";

const DahaFazlaGor = ({ text, wordLimit }) => {
  const [showFullText, setShowFullText] = React.useState(false);

  const handleToggle = () => {
    setShowFullText(!showFullText);
  };

  const words = text.split(" ");
  const isLongText = words.length > wordLimit;
  const displayedText = showFullText
    ? text
    : words.slice(0, wordLimit).join(" ") + (isLongText ? "..." : "");

  return (
    <div className="mb-4">
      <p className="text-sm text-gray-600 text-left">{displayedText}</p>
      {isLongText && (
        <button
          onClick={handleToggle}
          className="text-sm font-medium text-blue-900  hover:bg-blue-200 transition-colors duration-300 px-2 py-1 rounded"
        >
          {showFullText ? "Daha Az Gör" : "Daha Fazla Gör"}
        </button>
      )}
    </div>
  );
};

export default DahaFazlaGor;
