import React, { useState, useEffect } from 'react';
import DahaFazlaGor from '../components/DahaFazlaGor';

export default function Arsiv() {
  const [arsiv, setArsiv] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/Arsiv.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // Sort data by date
        const sortedData = data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setArsiv(sortedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError('Veriler yüklenirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
      });
  }, []);

  return (
    <div className="container mx-auto px-4 py-20 bg-gray-50">
      <h1 className="text-2xl sm:text-4xl font-bold text-blue-900 mb-8 text-center">Arşiv</h1>
      <div className="space-y-6">
        {error ? (
          <p>{error}</p>
        ) : (
          arsiv.map((item, index) => (
            <div
              key={index}
              className="bg-white p-4 sm:p-6 rounded-lg shadow-md border border-gray-200"
            >
              <div className="flex flex-col sm:flex-row items-start bg-gray-100 p-4 sm:p-6 rounded-lg shadow-md border border-gray-200">
                <div className="relative w-32 h-20 flex-shrink-0 mr-4">
                  <img
                    src={item.type === 'video' ? `https://img.youtube.com/vi/${item.link.split('v=')[1]}/0.jpg` : item.image}
                    alt="Content Thumbnail"
                    className="absolute inset-0 w-full h-full object-cover rounded"
                  />
                </div>
                <div className="flex-1">
                  <h3 className="text-lg font-semibold text-blue-900">
                    {item.title}
                  </h3>
                  <DahaFazlaGor text={item.description} wordLimit={50} />
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-block bg-blue-600 text-white font-medium text-sm px-4 py-2 rounded-lg shadow-md hover:bg-blue-700 hover:shadow-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition-all"
                  >
                    İzle
                  </a>
                  {/* <p className="text-sm text-gray-500 mt-2">
                    {new Date(item.date).toLocaleDateString('tr-TR', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </p> */}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
